// Message to the platform
export type EventTypes =
  | "start"
  | "gameover"
  | "ready"
  | "sound"
  | "preload-sounds"
  | "score"
  | "progress"
  | "timeleft"
  | "lives";

export interface GameOverData {
  score: number;
  uitslag?: any;
  custom?: any;
}
export type EventData = GameOverData | string | string[] | number;
export interface GameEvent {
  eventName: EventTypes;
  data?: EventData;
}

// Messages from the platform
export type PlatformEventTypes = "play" | "restart";
export interface PlatFormEvent {
  eventName: PlatformEventTypes;
}

export interface Game {
  play: any;
  restart: any;
}

export class Platform {
  private game: Game | undefined = undefined;
  constructor() {
    window.addEventListener("message", (ev: MessageEvent) => {
      this.receive(ev.data);
    });

    (<any>window).pew = this;
  }

  // Events from website
  private receive(data: PlatFormEvent) {
    if (!this.game) {
      console.error("Error: Game not set");
      return;
    }
    switch (data.eventName) {
      // Game is fully visible
      case "play":
        this.game.play();
        break;

      // Game is covered by game over page so it can reset it's state etc.
      case "restart":
        this.game.restart();
    }
  }

  // Called first time
  public init(game: Game, config: any) {
    this.game = game;

    let fonts: string[] = [];
    for (const [key, v] of Object.entries(config)) {
      if (!v) {
        continue;
      }
      const value = "" + v;
      if (
        key.toLowerCase().includes ("font") && !key.toLowerCase().includes("color") && !key.toLowerCase().includes("size") &&
        !fonts.includes(value) &&
        !excludedFonts.includes(value)
      ) {
        fonts.push(value);
      }
    }

    if (fonts.length > 0) {
      import("webfontloader").then((webfont) => {
        webfont.load({
          google: { families: fonts },
          active: () => {
            this.ready();
          },
        });
      });
    
    } else {
      this.ready();
    }
  }

  /**
   * Send play event when game actualy starts
   */
  public gamestarted() {
    this.sendEvent({ eventName: "start" });
  }


  /**
   * Send gameover event from game with endscore
   */
  public gameover(score: number, uitslag: any, custom: any) {
    this.sendEvent({ eventName: "gameover", data: { score, uitslag, custom } });
  }

  /**
   * Send ready event so the game becomes clickable
   */
  public ready() {
    this.sendEvent({ eventName: "ready" });
  }

  // Play sound in website
  public playSound(sound?: string) {
    if(sound)
    this.sendEvent({ eventName: "sound", data: (this.game as any).config.path + "/" + sound });
  }

  // Not used right now
  // Was used for loading sounds in website instead of in game
  public preloadSounds(config: any) {
    let files: string[] = [];

    for (const [key, v] of Object.entries(config)) {
      if (!v) {
        continue;
      }
      const value = config.path + "/" + v;
      if (!files.includes(value) && key.includes("Sound")) {
        files.push(value);
      }
    }
    this.sendEvent({
      eventName: "preload-sounds",
      data: files,
    });
  }

  /**
   * Send message to the game platform
   * @param event
   */
  private sendEvent(event: GameEvent) {
    window?.parent?.postMessage(event, "*");
  }

  // Send score to website UI
  public sendScore(score: number){
    // Put score in an object like this for Score-event animation
    this.sendEvent({eventName: "score", data: {score: score}})

    // Send score as a number for no Score-event animation
    // this.sendEvent({eventName: "score", data: score})
  }

  // Send progress to website UI
  // Can be sent as number (0-100) or string (5/20) (used by Progress Text UI element)
  public sendProgress(progress: number){
    this.sendEvent({eventName: "progress", data: progress})
  }

  // Send time left to website UI (percentage 0-100)
  public sendTimeleft(time: number){
    this.sendEvent({eventName: "timeleft", data: time})
  }

  // Send lives to website UI (any number)
  public sendLives(lives: number){
    this.sendEvent({eventName: "lives", data: lives})
  }
}

// Web font loader should skip these
const excludedFonts = [
  "Arial",
  "Helvetica",
  "Comic Sans MS",
  "Courier New",
  "Courier",
  "Georgia",
  "Lucida Sans Unicode",
  "Lucida Grande",
  "Geneva",
  "Times New Roman",
  "Trebuchet",
  "Verdana",
];
