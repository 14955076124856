import { Card, CardConfig } from "./Card";
import { ResultConfig } from "./Uitslag";
import { GameConfig, SwipeGame } from "./Game";
import { containsNumber } from "./Utility";
import path from "path";

export const DEFAULT_SETTINGS = {
  path: "https://mediadev.playsome.nl/games/swipegame/wietse_test/",
  randomize: "true",
  gamemode: "tinder",
  showCardTitle: "false",
  resultPath: "",
  revealResultText: "Tik hier!!!",
  question: "",
  cardBorderColor: "white",
  cardBorderWidth: 0,
  cardBorderRadius: 0,
  cardColor: "white",
  indicatorWidth: 75,
  indicatorPadding: 10,
  showQuestion: "true",
  results: "",
  cards: "",
  font: "Arial",
  showCardQuestion: "true",
};

export class Configurator {
  cards: CardConfig[] = [];

  async init(settings: any, cardsParsed: boolean) {
    let cards: CardConfig[];
    let results: ResultConfig[];

    if (cardsParsed) {
      cards = settings.cards.map((c: CardConfig) => {
        c.image = settings.path + "/" + c.image;
        return c;
      });
      // cards = cards.reverse();
      results = settings.results.map((r: ResultConfig) => {
        r.image =
          settings.path + "/" + (settings.resultPath || "") + "/" + r.image;
        return r;
      });
    } else if (settings.cards) {
      cards = settings.cards;
    } else {
      cards = await this.getCardsFromApi(settings.path);
    }

    const resultPath = settings.resultPath;

    const config: GameConfig = {
      path: settings.path,
      cards: cards,
      question: settings.question,
      showQuestion: cardsParsed
        ? settings.showQuestion
        : settings.showQuestion === "true",
      results: cardsParsed
        ? settings.results
        : this.getResults(settings.results, settings.path + "/" + resultPath),
      shuffleCards: cardsParsed
        ? settings.shuffleCards
        : settings.shuffleCards === "true",
      showCardTitle: cardsParsed
        ? settings.showCardTitle
        : settings.showCardTitle === "true",
      lives: settings.lives ? +settings.lives : undefined,
      timeLimit: settings.timeLimit ? +settings.timeLimit : undefined,
      cardLimit: +settings.cardLimit || undefined,
      randomResult: cardsParsed
        ? settings.randomResult
        : settings.randomResult === "true",
      resultPath: resultPath,
      font: settings.font,
      rightAnswerSound: settings.rightAnswerSound,
      wrongAnswerSound: settings.wrongAnswerSound,
      swipeSound: settings.swipeSound,
      fontColor: settings.fontColor,
      fontSize: settings.fontSize,
      cardContainerColor: settings.cardContainerColor,
      cardBorderColor: settings.cardBorderColor,
      cardBorderWidth: settings.cardBorderWidth,
      cardBorderRadius: settings.cardBorderRadius,
      cardColor: settings.cardColor,
      swipeLeftIndicatorImage: settings.swipeLeftIndicatorImage,
      swipeRightIndicatorImage: settings.swipeRightIndicatorImage,
      indicatorPadding: settings.indicatorPadding,
      indicatorWidth: settings.indicatorWidth,

      showCardQuestion: cardsParsed
        ? settings.showCardQuestion
        : settings.showCardQuestion === "true",

      cardQuestionColor: settings.cardQuestionColor,
      cardQuestionFont: settings.cardQuestionFont,
      cardQuestionSize: settings.cardQuestionSize,

      cardTitleColor: settings.cardTitleColor,
      cardTitleFont: settings.cardTitleFont,
      cardTitleSize: settings.cardTitleSize,

      questionColor: settings.questionColor,
      questionFont: settings.questionFont,
      questionSize: settings.questionSize,
    };

    const cardSrcs = config.cards.map((c) => c.image);
    const resultSrcs = config.results.map((r) => r.image);

    let imgs = [...cardSrcs];

    if (resultSrcs.length > 0) {
      imgs = imgs.concat(resultSrcs);
    }

    if (
      config.swipeLeftIndicatorImage &&
      config.swipeLeftIndicatorImage !== "false" &&
      (config.swipeLeftIndicatorImage as unknown as boolean) !== false
    ) {
      config.swipeLeftIndicatorImage =
        config.path + "/" + config.swipeLeftIndicatorImage;
      imgs.push(config.swipeLeftIndicatorImage);
    }

    if (
      config.swipeRightIndicatorImage &&
      config.swipeRightIndicatorImage !== "false" &&
      (config.swipeRightIndicatorImage as unknown as boolean) !== false
    ) {
      config.swipeRightIndicatorImage =
        config.path + "/" + config.swipeRightIndicatorImage;
      imgs.push(config.swipeRightIndicatorImage);
    }

    this.preloadImages(imgs).then(() => {
      new SwipeGame(config);
    });
  }

  async preloadImages(sources: string[]) {
    return new Promise<void>((resolve) => {
      let loadedCounter = 0;
      sources.forEach((src) => {
        const image = new Image();
        image.onload = () => {
          loadedCounter += 1;
          if (loadedCounter === sources.length) {
            resolve();
          }
        };
        image.onerror = () => {
          console.error(src + " was not found");
          loadedCounter += 1;
          if (loadedCounter === sources.length) {
            resolve();
          }
        };
        image.src = src;
      });
    });
  }

  getResults(resultsString: string, path: string) {
    let resultConfigs: ResultConfig[] = [];

    if (resultsString.length === 0) {
      return [];
    }

    let splitBy = ",";
    if (resultsString.includes("|")) {
      splitBy = "|";
    }

    const results = resultsString.split(";").filter((s) => s.trim().length > 0);

    results.forEach((u, i) => {
      let min;
      let max;
      let img;
      let title;
      let text;

      const split = u.split(splitBy);

      if (!containsNumber(u)) {
        min = 0;
        max = 0;
        img = split[0];
        title = split[1];
        if (split.length > 2) {
          text = split[2];
        }
      } else {
        if (i === 0) {
          min = -99999;
          max = split[0];
          img = split[1];
          title = split[2];
        } else if (i + 1 === results.length) {
          max = 99999;
          min = split[0];
          img = split[1];
          title = split[2];
        } else {
          const splitMinMax = split[0].split("-");
          min = splitMinMax[0];
          max = splitMinMax[1];
          img = split[1];
          title = split[2];
        }
      }

      // if (title.trim() === "") title = undefined;

      if (split.length > 3) {
        text = split[3];
      }

      resultConfigs.push({
        min: +min,
        max: +max,
        image: path + "/" + img,
        title: title,
        text: text,
      });
    });

    return resultConfigs;
  }

  async getCardsFromApi(
    rootPath: string,
    subPaths?: string[]
  ): Promise<CardConfig[]> {
    const apiUrl = process.env.API_URL;

    if (!apiUrl) {
      return [];
    }

    const split = rootPath.split("/games/");

    const root = split[0];
    const folder = split[1];

    let call = "";

    if (subPaths) {
      call = `?root=${this.slashToPlus(folder)}&goed=${this.slashToPlus(
        subPaths[0]
      )}&fout=${this.slashToPlus(subPaths[1])}`;
    } else {
      call = `?path=${this.slashToPlus(folder)}`;
    }

    let cardsJson: CardConfig[] = await (
      await fetch(apiUrl + call, { mode: "cors" })
    ).json();

    let cards: CardConfig[] = cardsJson.map((card: any) => {
      return {
        image: card.image.replace("/media", root),
        title: card.title,
        answer: card.answer,
      };
    });

    return cards;
  }

  slashToPlus(s: string) {
    return s.replaceAll("/", "+");
  }

  getCardsFromString(cardsString: string, path: string) {
    const cards = cardsString.split(";");

    const cardConfigs: CardConfig[] = [];

    let splitBy = ",";
    if (cardsString.includes("|")) {
      splitBy = "|";
    }

    cards.forEach((cardString) => {
      const split = cardString.split(splitBy);
      let cardConfig: CardConfig = {
        image: "",
        title: "",
      };
      if (split.length >= 3) {
        const name = split[0].trim();
        const likePoints = +split[1].trim();
        const dislikePoints = +split[2].trim();

        if (isNaN(likePoints) || isNaN(dislikePoints)) {
          console.error(cardString + " contains a number that is NaN");
          cardConfig = {
            image: path + name,
            title: name.split(".")[0].replaceAll("_", " "),
          };
        } else {
          cardConfig = {
            image: path + name,
            title: name.split(".")[0].replaceAll("_", " "),
            points: {
              like: +likePoints,
              dislike: +dislikePoints,
            },
          };
        }
      }
      if (split.length >= 4) {
        cardConfig.question = split[3].trim();
      }
      cardConfigs.push(cardConfig);
    });
    return cardConfigs;
  }
}
